<template>
  <div class="base-content">
    <query-frame
      :pageVisible="false"
      :searchVisible="false"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import queryMixin from '@/mixin/queryMixin';
import { carList, carDelete } from '../../api/car';

export default {
  name: 'CarList',
  mixins: [queryMixin],
  data() {
    return {
      dictOptions: {},
      dataList: [],
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '车牌号',
          dataIndex: 'carNumber',
          key: 'carNumber',
          ellipsis: true,
        },
        {
          title: '车主姓名',
          dataIndex: 'ownerName',
          key: 'ownerName',
          ellipsis: true,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          ellipsis: true,
        },
        {
          title: '注册登记日期',
          dataIndex: 'registerTime',
          key: 'registerTime',
          ellipsis: true,
        },
        {
          title: '保险到期日期',
          dataIndex: 'insuranceExpirationTime',
          key: 'insuranceExpirationTime',
          ellipsis: true,
        },
        {
          title: '单位介绍人',
          dataIndex: 'introducer',
          key: 'introducer',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 220,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return {
              children:
                <section>
                  <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
                  <a-divider type="vertical"></a-divider>
                  <a-popconfirm
                    title="确定删除?"
                    v-on:confirm= {() => this.handleDelete(row)}
                  >
                    <a href=" javascript:;">删除</a>
                  </a-popconfirm>
                </section>,
            };
          }
        },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取列表
    getData() {
      this.dataList = [];
      carList().then((data) => {
        // console.log(data);
        data.forEach((item) => {
          item.key = item.carId;
        });
        this.dataList = data;
      });
    },
    // 添加
    handleAdd() {
      this.$router.push({
        name: 'carAdd'
      });
    },

    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'carInfoEdit',
        params: {
          id: row.carNumber,
        }
      });
    },
    // 删除
    handleDelete(row) {
      const params = {
        ...row
      };
      carDelete(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
  }
};
</script>

<style scoped>

</style>
